import React, { useState, useRef, useEffect } from "react";
import { Table, Pagination, Badge, Dropdown } from "react-bootstrap";
import moment from 'moment';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange,DateRangePicker } from 'react-date-range';
import AuthService from "../../pages/Auth";


import { Link } from "react-router-dom";

const SpendingDataTable = () => {
   const [data, setData] = useState(
      document.querySelectorAll("#spendingTable tbody tr")
   );
   const [userreport, setuserreport] = useState([]);
   const [userreportcam, setuserreportcam] = useState([]);
   const [filter, setfilter] = useState('d-none');
   const [loading, setloading] = useState('');
   const [userreportyesdate, setuserreportyesdate] = useState(0);
   const [userreportweekspent, setuserreportweekspent] = useState(0);
   const [userreportweekconversiont, setuserreportweekconversiont] = useState(0);
   const [userreportweekcpa, setuserreportweekcpa] = useState(0);
   const [userreportweekclicks, setuserreportweekclicks] = useState(0);
   const [userreportweekctr, setuserreportweekctr] = useState(0);
   const [userreportweekcpm, setuserreportweekcpm] = useState(0);
   const [userreportweekimpressions, setuserreportweekimpressions] = useState(0);
   const [selectedDate, setSelectedDate] = useState(null);

   const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection'
      }
    ]);
   const sort = 30;
   const activePag = useRef(0);
   const myref = useRef(null);
   const [test, settest] = useState(0);

   const handleSelect = (date) => {
      setState([date.selection]);
    }
    const showfilter = (date) => {
      if(filter == 'd-none'){
         setfilter('');
      }else{
         setfilter('d-none');
      }
      
    }
    

   // Active data
   const chageData = (frist, sec) => {
      for (var i = 0; i < data.length; ++i) {
         if (i >= frist && i < sec) {
            data[i].classList.remove("d-none");
         } else {
            data[i].classList.add("d-none");
         }
      }
   };
   const handleDateClick = (date) => {
      myref.current.scrollIntoView({ behavior: 'smooth' });
      setSelectedDate(date);
      myref.current.scrollIntoView({ behavior: 'smooth' });
    };

    // Active data
    const filterdata = async () => {
      console.log(state[0].startDate);
      const user = AuthService.getCurrentUser();
      setfilter('d-none');
      setloading('loading');
         var startdate = state[0].startDate.toJSON();
         var enddate = state[0].endDate.toJSON();
         try {
            const response = await fetch(`${AuthService.getbaseurl()}/wp-json/api/v1/get_detail_last_by_date_new`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ eid: user.addroll_eid,start_date: startdate,end_date: enddate,user_id:user.user_id }),
            });
           if (response.status) {
              const jsonData = await response.json();
               if(jsonData.status_code == 400){
   
               }
                  if(jsonData.status_code == 200){
   
                  setuserreport(jsonData.data.datedata);
                  console.log(jsonData.data.campdata);
                  setuserreportcam(jsonData.data.campdata);
                  setuserreportyesdate(jsonData.data.yesspent.toFixed(2));
                  setuserreportweekspent(jsonData.data.avspent.toFixed(2));
                  setuserreportweekconversiont(jsonData.data.avconversions);
                  setuserreportweekcpa(jsonData.data.avcpa);
                  setuserreportweekclicks(jsonData.data.avclicks);
                  setuserreportweekctr(jsonData.data.avctr.toFixed(2));
                  setuserreportweekcpm(jsonData.data.avcpm);
                  setuserreportweekimpressions(jsonData.data.avimpressions);
                     setloading('');
                     setSelectedDate(null);
            
                  }
            }
          } catch (error) {
            console.log('Error:', error);
          }
   };
   // use effect
   useEffect(() => {
      setData(document.querySelectorAll("#spendingTable tbody tr"));
      chackboxFun();

      moment.locale('en');

      const user = AuthService.getCurrentUser();
      if (user && user.addroll_eid != '') {
        const getreportHandler = async (e) => {
        try {
         const response = await fetch(`${AuthService.getbaseurl()}/wp-json/api/v1/get_detail_last_month_new`, {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
           },
           body: JSON.stringify({ eid: user.addroll_eid,user_id:user.user_id }),
         });
        if (response.status) {
           const jsonData = await response.json();
            if(jsonData.status_code == 400){

            }
           
               if(jsonData.status_code == 200){

                  console.log(jsonData.data);
                  setuserreport(jsonData.data.datedata);
                  console.log(jsonData.data.campdata);
                  setuserreportcam(jsonData.data.campdata);
                  setuserreportyesdate(jsonData.data.yesspent.toFixed(2));
                  setuserreportweekspent(jsonData.data.avspent.toFixed(2));
                  setuserreportweekconversiont(jsonData.data.avconversions);
                  setuserreportweekcpa(jsonData.data.avcpa);
                  setuserreportweekclicks(jsonData.data.avclicks);
                  setuserreportweekctr(jsonData.data.avctr.toFixed(2));
                  setuserreportweekcpm(jsonData.data.avcpm);
                  setuserreportweekimpressions(jsonData.data.avimpressions);

               }
         }
       } catch (error) {
         console.log('Error:', error);
       }
        
      };
      getreportHandler();

      }

   }, [test]);

   // Active pagginarion
   activePag.current === 0 && chageData(0, sort);
   // paggination
   let paggination = Array(Math.ceil(data.length / sort))
      .fill()
      .map((_, i) => i + 1);

   // Active paggination & chage data
   const onClick = (i) => {
      activePag.current = i;
      chageData(activePag.current * sort, (activePag.current + 1) * sort);
      settest(i);
   };
   const chackbox = document.querySelectorAll(".all_spending_checkbox input");
   const motherChackBox = document.querySelector(".all_spending_strg input");
   // console.log(document.querySelectorAll(".all_spending_strg input")[0].checked);
   const chackboxFun = (type) => {
      for (let i = 0; i < chackbox.length; i++) {
         const element = chackbox[i];
         if (type === "all") {
            if (motherChackBox.checked) {
               element.checked = true;
            } else {
               element.checked = false;
            }
         } else {
            if (!element.checked) {
               motherChackBox.checked = false;
               break;
            } else {
               motherChackBox.checked = true;
            }
         }
      }
   };

   return (
      <>
      <div className="filter_section_wrap">
        <span className="filterbutton" onClick={showfilter}>Search by Date</span> 
        <span className={`Daterange_filter ${filter}`}>
      <DateRange
         editableDateInputs={true}
         moveRangeOnFirstSelection={false}
         onChange={handleSelect}
         ranges={state}
         maxDate={new Date()}
         />
         <span className="filter_record" onClick={filterdata}>Filter</span> 
      </span>
      </div>
         <div className={`table-responsive ${loading}`}>
            <div className="dataTables_wrapper no-footer">
               <table
                  id="spendingTable"
                  className="display mb-4 dataTablesCard card-table dataTable no-footer"
                  style={{ width: "100%" }}
               >
                  <thead>
                     <tr role="row">
                       
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Ads ID: activate to sort column ascending"
                          
                        >
                           Date
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Date Published: activate to sort column ascending"
                          
                        >
                           Impressions
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Yesterday Spending: activate to sort column ascending"
                           
                        >
                           Clicks
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           
                        >
                          CTR
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Today Spending: activate to sort column ascending"
                          
                        >
                           Cost
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           
                        >
                           CPM
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           
                        >
                           Frequency
                        </th>
                        
                        
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Remaining: activate to sort column ascending"
                           
                        >
                           Conversion
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           style={{ width: 50 }}
                        >
                           CPA
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           style={{ width: 50 }}
                        >
                           View
                        </th>
                       
                     </tr>
                  </thead>
                  <tbody>
                  <tr role="row" className={'odd'}>
   
                    <td><span className="font-w600">Total</span></td>
                    <td><span className="font-w600">{userreportweekimpressions}</span></td>
                    <td>
                    <span className="font-w600">{userreportweekclicks}</span>
                    </td>
                    <td>
                    <span className="font-w600">{userreportweekctr}%</span>
                    </td>
                    <td>
                       <span className="font-w600">${userreportweekspent}</span>
                    </td>
                    <td>
                    <span className="font-w600">${userreportweekcpm.toFixed(2)}</span>
                    </td>
                    <td>
                    <span className="font-w600"></span>
                    </td>
                    
                    <td>
                    <span className="font-w600">{userreportweekconversiont}</span>
                    </td>
                    <td>
                     <span className="font-w600">${userreportweekcpa.toFixed(2)}</span>
                    </td>
                    <td>
                     <span className="font-w600"></span>
                    </td>
                 </tr>
                  {userreport.slice(0).reverse().map((student, index) => (  

                     <tr role="row" className={(index%2 == 0)?'even':'odd'}>
                    
                     <td >{moment(student.date, 'YYYY-MM-DD').format('MMM DD, YYYY')}</td>
                     <td>{student.impressions}</td>
                     <td>
                        {student.clicks}
                     </td>
                     <td>
                     {(student.ctr != null)?student.ctr.toFixed(2):'0'}%
                     </td>
                     <td>
                        <span className="font-w600">{AuthService.price_data(student.cost)}</span>
                     </td>
                     <td>
                     {AuthService.price_data(student.cpm)}
                     </td>
                     <td>
                     {(student.frequency_cap != null)?student.frequency_cap.toFixed(2):'N/A'}
                     </td>
                     <td>
                     {student.conversions}
                     </td>
                     <td>
                     {AuthService.price_data(student.cpa)}
                     </td>
                     <td>
                    <span onClick={() => handleDateClick(student.date)}><a style={{ color: '#f2c600' }}  href="#camtable" >Details</a></span>
                     </td>

                  </tr>

                     ))} 
                  </tbody>
               </table>
               <div className="d-sm-flex text-center justify-content-between align-items-center">
                 
               </div>
            </div>


            <div id="camtable" ref={myref} className={(selectedDate != null)?'dataTables_wrapper camtable no-footer ':'dataTables_wrapper camtable no-footer d-none'}>
              <h2 style={{ textAlign: "center" }}>Ad Group Stats</h2>
               <table
                  id="spendingTable"
                  className="display mb-4 dataTablesCard card-table dataTable no-footer"
                  style={{ width: "100%" }}
               >
                  <thead>
                     <tr role="row">
                       
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Ads ID: activate to sort column ascending"
                           style={{ width: 250 }}
                        >
                           {moment(selectedDate, 'YYYY-MM-DD').format('MMM DD, YYYY')}
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Date Published: activate to sort column ascending"
                           style={{ width: 124 }}
                        >
                           Impressions
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Yesterday Spending: activate to sort column ascending"
                           style={{ width: 162 }}
                        >
                           Clicks
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           style={{ width: 50 }}
                        >
                          CTR
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Today Spending: activate to sort column ascending"
                           style={{ width: 130 }}
                        >
                           Cost
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           style={{ width: 50 }}
                        >
                           CPM
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           style={{ width: 50 }}
                        >
                           Frequency cap
                        </th>
                        
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Remaining: activate to sort column ascending"
                           style={{ width: 87 }}
                        >
                           Conversion
                        </th>
                        <th
                           className="sorting"
                           tabIndex={0}
                           aria-controls="example5"
                           rowSpan={1}
                           colSpan={1}
                           aria-label="Total Spending: activate to sort column ascending"
                           style={{ width: 50 }}
                        >
                           CPA
                        </th>
                       
                     </tr>
                  </thead>
                  <tbody>
                  {userreportcam.map((student, index) => (  selectedDate === student.date && (

                     <tr role="row" className={(index%2 == 0)?`${student.date} even`:`${student.date} odd`}>
                    
                     <td>{student.name}</td>
                     <td>{student.impressions}</td>
                     <td>
                        {student.clicks}
                     </td>
                     <td>
                     {(student.ctr != null)?student.ctr.toFixed(2):'0'}%
                     </td>
                     <td>
                        <span className="font-w600">{AuthService.price_data(student.cost)}</span>
                     </td>
                     <td>
                     {AuthService.price_data(student.cpm)}
                     </td>
                     <td>
                     {(student.frequency_cap != null)?student.frequency_cap.toFixed(2):'N/A'}
                     </td>
                     <td>
                     {student.conversions}
                     </td>
                     <td>
                     {AuthService.price_data(student.cpa)}
                     </td>
                  </tr>
                  )
                     ))} 
                  </tbody>
               </table>
               <div className="d-sm-flex text-center justify-content-between align-items-center">
                 
               </div>
            </div>



         </div>
      </>
   );
};

export default SpendingDataTable;
