import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import AuthService from "./Auth";
import logo from "../../images/logo.png";
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";


const Login = ({ history}) => {
   const [loginData, setLoginData] = useState({});
   const [loggedIn, setLoggedIn] = useState(false);
   const [errormessage, seterrormessage] = useState('');
   const [loadingbutton, setloadingbutton] = useState('');
   const [deviceWidth, setdeviceWidth] = useState(window.innerWidth);
   
   console.log("inner"+ deviceWidth);
   const handleBlur = (e) => {
      const newLoginData = { ...loginData };
      newLoginData[e.target.name] = e.target.value;
      setLoginData(newLoginData);
   };
   const responseMessage = async (e) => {
         console.log(e);
         var decodedHeader = jwt_decode(e.credential, { header: false });
         console.log(decodedHeader.email);
         try {
            const response = await fetch( `${AuthService.getbaseurl()}/wp-json/api/v1/social_login`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ email: decodedHeader.email}),
            });
           if (response.status) {
             
              const jsonData = await response.json();
              setloadingbutton('');
               if(jsonData.status_code == 400){
                  seterrormessage(jsonData.message);
                  
               }
                  if(jsonData.data.user_id > 0){
                     AuthService.setCurrentUser(jsonData.data);
                     window.location.reload();
                  }
              
            }
           else {
            
            const newLoginData = { ...loginData };
              newLoginData['email'] = "";
              newLoginData['password'] = "";
              setLoginData(newLoginData);
              console.error('Login failed');
            alert('Incorrect email or password. Please try again!');
            }
            
            
          } catch (error) {
            console.log('Error:', error);
            
          }


   }
   const errorMessage = async (e) => {

   }
   const submitHandler = async (e) => {
      e.preventDefault();
	  console.log(loginData);
	  setloadingbutton('loading');
	try {
      const response = await fetch(`${AuthService.getbaseurl()}/wp-json/api/v1/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: loginData.email, password: loginData.password }),
      });
	  if (response.status) {
       
        const jsonData = await response.json();
        setloadingbutton('');
         if(jsonData.status_code == 400){
            seterrormessage(jsonData.message);
         }
            if(jsonData.data.user_id > 0){
               AuthService.setCurrentUser(jsonData.data);
               window.location.reload();
            }
        
      }
	  else {
      
		const newLoginData = { ...loginData };
		  newLoginData['email'] = "";
		  newLoginData['password'] = "";
		  setLoginData(newLoginData);
        console.error('Login failed');
		alert('Incorrect email or password. Please try again!');
      }
      
      
    } catch (error) {
      console.log('Error:', error);
      
    }
	
      //history.push("/");
   };

   return (
      <div className="authincation h-100 p-meddle">
         <div className="container h-100">
            <div className="row justify-content-center h-100 align-items-center">
               <div className="col-md-6">
                  <div className="authincation-content">
                     <div className="row no-gutters">
                        <div className="col-xl-12">
                           <div className="auth-form">
                           <h4 className="text-center mb-4">
                           <img className="main_logo_image" src={logo} alt="" />
                           </h4>
                              <h4 className="text-center mb-4">
                                 
                              </h4>
                              <form
                                 action=""
                                 onSubmit={(e) => submitHandler(e)}
                              >
                                 <div className="message">
                                       {errormessage}
                                 </div>
                                 <div className="form-group">
                                    <label className="mb-1">
                                       <strong>Email</strong>
                                    </label>
                                    <input
                                       type="email"
                                       className="form-control"
                                       defaultValue=""
                                       name="email"
                                       onChange={handleBlur}
                                    />
                                 </div>
                                 <div className="form-group">
                                    <label className="mb-1">
                                       <strong>Password</strong>
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       defaultValue=""
                                       name="password"
                                       onChange={handleBlur}
                                    />
                                 </div>
                                 <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                    <div className="form-group">
                                       <div className="loginremeber custom-control custom-checkbox ml-1">
                                          <input
                                             type="checkbox"
                                             className="custom-control-input"
                                             id="basic_checkbox_1"
                                          />
                                          <label
                                             className="custom-control-label"
                                             htmlFor="basic_checkbox_1"
                                          >
                                             Remember this browser for 2 weeks
                                          </label>
                                            <button type="button" className="btn-link" onClick= {(e) => {
                                                                                       e.preventDefault();
                                                                                       window.location.href=`${AuthService.getbaseurl()}/my-account/lost-password/`;
                                                                         }}>Forgot Password?</button>
                                       </div>
                                    </div>
                                   
                                 </div>
                                 <div className="text-center">
                                    <input
                                       type="submit"
                                       value="Sign Me In"
                                       className={`btn btn-primary btn-block ${loadingbutton}`}
                                    />
                                 </div>
                              </form>
                              <div className="socialbutton text-center mb-4 mt-4">
                              <GoogleLogin width={`${deviceWidth}px`} onSuccess={responseMessage} onError={errorMessage} />
                              </div>
                              <div className="new-account mt-3">
                                 <p>
                                    Don't have an account?{" "}
										{/*<Link
                                       className="text-primary"
                                       to="/page-register"
                                    >
                                       Sign up
										</Link>*/}
                                    <button type="button" size="large" className="btn btn-link" onClick= {(e) => {
                                                                                       e.preventDefault();
                                                                                       window.location.href=`${AuthService.getbaseurl()}/?register=1`;
                                                                                       }}>Sign up</button>
                                 </p>
                              </div>
                           </div>
						   {loggedIn ? <Redirect to = "/dashboard" />:""}
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Login;
