import React, { useState } from "react";
const getCurrentUser = () => {
   return JSON.parse(localStorage.getItem("user"));
 };
 const setCurrentUser = (data) => {
   localStorage.setItem("user", JSON.stringify(data));
   return 'Saved !';
 };
 const price_data = (price) => {
  if(price != null){
    var prcecal =  JSON.parse(localStorage.getItem("user"));
     
    return "$"+price.toFixed(2);
  }else{
    return "$0";
  }
};
  const price = (price) => {
    if(price != null){
      return "$"+price;
    }else{
      return "$0";
    }
};
 const logout = () => {
   console.log("LOGED OUT");
   localStorage.removeItem("user");
  // window.location.href = "https://staging2.everywherebee.com/account/logout";
 };
 const getbaseurl = () => {
    return "https://staging2.everywherebee.com/account";
};

 const AuthService = {
   setCurrentUser,
   logout,
   getCurrentUser,
   price_data,
   getbaseurl,
   price,
 }

 export default AuthService;
