import React,{ useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Nav, Tab } from "react-bootstrap";

import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

import AuthService from "../../pages/Auth";

import SpendingDataTable from "./SpendingDataTable";

const ApexNagetivePosative3 = loadable(() =>
   pMinDelay(import("../charts/apexcharts/NagetivePositive3"), 500)
);
const ApexRedialBar2 = loadable(() =>
   pMinDelay(import("../charts/apexcharts/RadialBar2"), 500)
);
const LineChart7 = loadable(() =>
   pMinDelay(import("../charts/Chartjs/line7"), 0)
);

function Home() {

   const [currentUser, setCurrentUser] = useState([]);
   const [userreportyesdate, setuserreportyesdate] = useState('0');
   const [userreportweekspent, setuserreportweekspent] = useState('0');
   const [userreportweekconversiont, setuserreportweekconversiont] = useState('0');
   const [userreportweekcpa, setuserreportweekcpa] = useState('0');

   const [currentime, setcurrentime] = useState('');
   const [currendate, setcurrendate] = useState('');
   useEffect(() => {
      const user = AuthService.getCurrentUser();
      if (user && user.addroll_eid != '') {
        setCurrentUser(user);
        const getreportHandler = async (e) => {
        try {
         const response = await fetch(`${AuthService.getbaseurl()}/wp-json/api/v1/get_detail_last_month_new`, {
           method: 'POST',
           headers: {
             'Content-Type': 'application/json',
           },
           body: JSON.stringify({ eid: user.addroll_eid,user_id:user.user_id }),
         });
        if (response.status) {
           const jsonData = await response.json();
            if(jsonData.status_code == 400){

            }
           
               if(jsonData.status_code == 200){
            
               
               setuserreportyesdate(jsonData.data.yesspent.toFixed(2));
               setuserreportweekspent(jsonData.data.avspent.toFixed(2));
               setuserreportweekconversiont(jsonData.data.avconversions);
               setuserreportweekcpa(jsonData.data.avcpa.toFixed(2));

               
               }
         }
       } catch (error) {
         console.log('Error:', error);
       }
        
      };
      getreportHandler();

      }

      var date = new Date();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      setcurrentime(strTime);
      const monthNames = ["January", "February", "March", "April", "May", "June",
         "July", "August", "September", "October", "November", "December"
         ];
      var daysname = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      var monethname =   monthNames[date.getMonth()];
      var dayName = daysname[date.getDay()];
      var todate =   date.getDate();
      var toyear =   date.getFullYear();
      setcurrendate(dayName+",  "+todate+" "+monethname+" "+toyear);
    }, []);

   return (
      <>
         <div className="form-head d-flex mb-0 mb-lg-4 align-items-start">
            <div className="mr-auto d-none d-lg-block">
               <h2 className="text-black font-w600 mb-1">Dashboard</h2>
               <p className="mb-0"></p>
            </div>
            <div className="d-none d-lg-flex align-items-center">
               <div className="text-right">
                  <h3 className="fs-20 text-black mb-0">{currentime}</h3>
                  <span className="fs-14">{currendate}</span>
               </div>
              
            </div>
         </div>
         <div className="row">
            <div className="col-lg-8 col-xxl-12">
               <div className="row">
                  
                  <div className="col-xl-12">
                     <div className="card">
                        <div className="card-body d-flex flex-wrap p-0">
                           <div className="col-lg-3 col-sm-3  media spending-bx">
                             
                              <div className="media-body text-center">
                                 <p className="fs-18 mb-2">Spend Yesterday</p>
                                     <span className="fs-34 font-w600">${userreportyesdate}</span>
                              </div>
                           </div>
                           <div className="col-lg-3 col-sm-3 media spending-bx-nobod pl-2">
                              <div className="media-body text-center">
                                 <p className="fs-18 mb-2">Last 7 Days Spend</p>
                                 <span className="fs-34 font-w600">${userreportweekspent}</span>
                              </div>
                            
                           </div>
                           <div className="col-lg-3 col-sm-3  media spending-bx-nobod lightcolor">
                              
                              <div className="media-body text-center">
                                 <p className="fs-18 mb-2">Last 7 Days Conversion</p>
                                     <span className="fs-34 font-w600">{userreportweekconversiont}</span>
                              </div>
                           </div>
                           <div className="col-lg-3 col-sm-3 media spending-bx pl-2">
                              <div className="media-body text-center">
                                 <p className="fs-18 mb-2">Last 7 Days CPA</p>
                                 <span className="fs-34 font-w600">${userreportweekcpa}</span>
                              </div>
                             
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="col-xl-12">
                     <div className="card" id="user-activity">
                         <SpendingDataTable />
                     </div>
                  </div>
               </div>
            </div>
        
         </div>
         {/* </div>
        </div>
      </div> */}
      </>
   );
}

export default Home;