import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
   return (
      <div className="footer">
         <div className="copyright">
            <p>
               Copyright © Designed &amp; Developed by{" "}
               <a href="https://everywherebee.com" target="_blank">
               everywherebee.com
               </a>{" "}
               2024
            </p>
         </div>
      </div>
   );
};

export default Footer;
