import { Route, Redirect } from "react-router-dom";

// Requirement 3.
// It checks if the user is authenticated, if they are,
// it renders the "component" prop. If not, it redirects
// the user to /login.

function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        // return fakeAuth.isAuthenticated === true ? (
        const token = localStorage.getItem("token");
        console.log("token:", token);
        return token ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
export default PrivateRoute;
