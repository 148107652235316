import Reac, { useState, useEffect }  from "react";

import { Link } from "react-router-dom";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/profile/pic1.jpg";
import avatar from "../../../images/avatar/1.jpg";
import { Dropdown } from "react-bootstrap";
import ProfileSidebar from "../ProfileSidebar";
import { useHistory } from "react-router-dom";

import AuthService from "../../pages/Auth";

const Header = ({
   onNote,
   toggle,
   onProfile,
   onNotification,
   onBox,
   showProfileSideBar,
   showProfile,
}) => {
   let path = window.location.pathname.split("/");
   path = path[path.length - 1];
   path = path === true;
   const history = useHistory();
   const logoutuser = async (e) => {
      AuthService.logout();
      var baseurl = AuthService.getbaseurl();
      document.location.href=baseurl+'/logout';
   }

   const [currentUser, setCurrentUser] = useState([]);
  
   useEffect(() => {
      const user = AuthService.getCurrentUser();
      if (user) {
        setCurrentUser(user);
        console.log(user);
        console.log("user set:"+user);
      }
    }, []);



   return (
      <div className="header">
         <div className="header-content">
            <nav className="navbar navbar-expand">
               <div className="collapse navbar-collapse justify-content-between">
                  <div className="header-left">
                    
                  </div>

                  <ul className="navbar-nav header-right">
                    
                    
                     {!path && (
                        <li className="nav-item dropdown header-profile">
                           <Link
                              className="nav-link"
                              to="#"
                              role="button"
                              onClick={() => onProfile()}
                           >
                              <div className="header-info">
                                 <span>{currentUser.first_name} {currentUser.last_name}</span>
                              </div>
                              <img src={currentUser.getUserUrl} width={20} alt="" />
                           </Link>
                           <div
                              className={`dropdown-menu dropdown-menu-right ${
                                 toggle === "profile" ? "show" : ""
                              }`}
                           >
                              <Link
                                 to="/app-profile"
                                 className="dropdown-item ai-icon"
                              >
                                 <svg
                                    id="icon-user1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-primary"
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                 >
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                                    <circle cx={12} cy={7} r={4} />
                                 </svg>
                                 <span className="ml-2">Settings</span>
                              </Link>
                              <Link
                                 to="/"
                                 className="dropdown-item ai-icon"
                                 onClick={() => logoutuser()}
                              >
                                 <svg
                                    id="icon-logout"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="text-danger"
                                    width={18}
                                    height={18}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                 >
                                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                                    <polyline points="16 17 21 12 16 7" />
                                    <line x1={21} y1={12} x2={9} y2={12} />
                                 </svg>
                                 <span className="ml-2" onClick={() => logoutuser()}>Logout </span>
                              </Link>
                           </div>
                        </li>
                     )}

                     {path && (
                        <li className="nav-item dropdown d-none d-xl-flex">
                           <Link className="btn btn-primary" to="/new-compaign">
                              + New Campaign
                           </Link>
                        </li>
                     )}
                     {path && !showProfileSideBar && (
                        <li className="nav-item dropdown header-profile">
                           <Link
                              className="nav-link"
                              to="#"
                              role="button"
                              onClick={() => showProfile()}
                           >
                              <div className="header-info">
                                 <span>
                                    Hello, <strong>Samuel</strong>
                                 </span>
                              </div>

                              <img src={profile} width={20} alt="" />
                           </Link>
                        </li>
                     )}
                  </ul>
               </div>
            </nav>
         </div>
      </div>
   );
};

export default Header;
