import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
//** Import Image */
import profile01 from "../../../../images/profile/1.jpg";
import profile02 from "../../../../images/profile/2.jpg";
import profile03 from "../../../../images/profile/3.jpg";
import profile04 from "../../../../images/profile/4.jpg";
import profile05 from "../../../../images/profile/5.jpg";
import profile06 from "../../../../images/profile/6.jpg";
import profile07 from "../../../../images/profile/7.jpg";
import profile08 from "../../../../images/profile/8.jpg";
import profile09 from "../../../../images/profile/9.jpg";
import profile from "../../../../images/profile/profile.png";
import { Dropdown, Button, Modal } from "react-bootstrap";

import PageTitle from "../../../layouts/PageTitle";

import AuthService from "../../../pages/Auth";

import { SRLWrapper } from "simple-react-lightbox";

const AppProfile = () => {
   const [activeToggle, setActiveToggle] = useState("setting");
   const [sendMessage, setSendMessage] = useState(false);
   const [replay, setReplay] = useState(false);
   const [currentUser, setCurrentUser] = useState([]);
   const [walletbalance, setwalletbalance] = useState('0');
   const [errormessage, seterrormessage] = useState('');
   const [loadingbutton, setloadingbutton] = useState('');
   const [message, setmessage] = useState('');
   const [formData, setFormData] = useState({});

   useEffect(() => {
      const user = AuthService.getCurrentUser();
      if (user) {
        setCurrentUser(user);
        setFormData(user);
        setwalletbalance(user.wallet_balance);
      }
    }, []);

 // Handler function for input changes
 const handleInputChange = (e) => {
   const { name, value } = e.target;
   setFormData({
     ...formData,
     [name]: value,
   });

   
 };

   // Handler function for form submission
   const handleSubmit =  async (e) => {
      e.preventDefault();
      // Perform the update action with formData
      console.log('Updated data:', formData);

      try {
            const response = await fetch( `${AuthService.getbaseurl()}/wp-json/api/v1/update_user_data`, {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
            });
         if (response.status) {
            const jsonData = await response.json();
            setloadingbutton('');
               if(jsonData.status_code == 400){
                  seterrormessage(jsonData.message);
               }else{
                  seterrormessage(jsonData.message);
                  AuthService.setCurrentUser(formData);
               }
            }
         } catch (error) {
            console.log('Error:', error);
            
         }

      // You can send the formData to your backend for further processing
    };

    
   const options = {
      settings: {
         overlayColor: "#000000",
      },
   };
   return (
      <Fragment>
         <div className="row">
            <div className="col-lg-12">
               <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                     <div className="photo-content">
                        <div className="cover-photo444"></div>
                     </div>
                     <div className="profile-info">
                        <div className="profile-photo">
                           <img
                              src={currentUser.getUserUrl}
                              className="img-fluid rounded-circle"
                              alt="profile"
                           />
                        </div>
                        <div className="profile-details">
                           <div className="profile-name px-3 pt-2">
                              <h4 className="text-primary mb-0">
                              {currentUser.first_name} {currentUser.last_name}
                              </h4>
                              <p>{currentUser.company}</p>
                           </div>
                           <div className="profile-email px-2 pt-2">
                              <h4 className="text-muted mb-0">
                              {currentUser.user_email}
                              </h4>
                              <p>Email</p>
                           </div>
                           <div className="profile-wallet px-2 pt-2">
                              <h4 className="text-muted mb-0">
                              {AuthService.price(walletbalance)}
                              </h4>
                              <p>Wallet Balance</p>
                           </div>
                           <Button
                                    variant=""
                                    type="button"
                                    className="btn btn-primary mb-1 mr-1"
                                    onClick= {(e) => {
                                       e.preventDefault();
                                       window.location.href=`${AuthService.getbaseurl()}/my-account/woo-wallet/?loginemail=${currentUser.user_email}`;
                                       }}
                                 >
                                    <span>Transaction History</span>
                                 </Button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="profile-tab">
                        <div className="custom-tab-1">
                          
                           <div className="tab-content">
                           
                              <div
                                 id="profile-settings"
                                 className={`tab-pane fade ${
                                    activeToggle === "setting"
                                       ? "active show"
                                       : ""
                                 }`}
                              >
                                 <div className="pt-3">
                                    <div className="settings-form">
                                       <h4 className="text-primary">
                                          Account Setting
                                       </h4>
                                       <form
                                          onSubmit={handleSubmit}
                                       >
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>First Name</label>
                                                <input
                                                   type="text"
                                                   placeholder="First Name"
                                                   className="form-control"
                                                   name="first_name"
                                                   value={formData.first_name}
                                                   onChange={handleInputChange}
                                                   
                                                />
                                             </div>
                                             <div className="form-group col-md-6">
                                                <label>Last Name</label>
                                                <input
                                                   type="text"
                                                   placeholder="Last Name"
                                                   className="form-control"
                                                   name="last_name"
                                                   value={formData.last_name}
                                                   onChange={handleInputChange}
                                                />
                                             </div>
                                          </div>
                                          <div className="form-group">
                                             <label>Address</label>
                                             <input
                                                type="text"
                                                placeholder="1234 Main St"
                                                className="form-control"
                                                name="billing_address_1"
                                                value={formData.billing_address_1}
                                                onChange={handleInputChange}
                                             />
                                          </div>
                                          <div className="form-group">
                                             <label>Address 2</label>
                                             <input
                                                type="text"
                                                placeholder="Apartment, studio, or floor"
                                                className="form-control"
                                                name="billing_address_2"
                                                value={formData.billing_address_2}
                                                onChange={handleInputChange}
                                             />
                                          </div>
                                          <div className="form-row">
                                             <div className="form-group col-md-6">
                                                <label>City</label>
                                                <input
                                                   type="text"
                                                   className="form-control"
                                                   name="billing_city"
                                                   value={formData.billing_city}
                                                   onChange={handleInputChange}
                                                />
                                             </div>
                                             <div className="form-group col-md-2">
                                                <label>Zip</label>
                                                <input
                                                   type="text"
                                                   className="form-control"
                                                   name="billing_postcode"
                                                   value={formData.billing_postcode}
                                                   onChange={handleInputChange}
                                                />
                                             </div>
                                          </div>
                                          <div className="form-group">
                                             <div className="custom-control custom-checkbox">
                                                <input
                                                   type="checkbox"
                                                   className="custom-control-input"
                                                   id="gridCheck"
                                                />
                                                <label
                                                   className="custom-control-label"
                                                   htmlFor="gridCheck"
                                                >
                                                   Check me out
                                                </label>
                                             </div>
                                          </div>
                                          <div className="message">
                                                {errormessage}
                                          </div>
                                          <button
                                             className="btn btn-primary"
                                             type="submit"
                                          >
                                             Update
                                          </button>
                                       </form>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default AppProfile;
