import React from "react";
import ReactDOM from "react-dom";
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from "./App";
// Login page
//import Login from "./jsx/pages/Login";

/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";

ReactDOM.render(
   <GoogleOAuthProvider clientId="855076884960-f438oks5k8t7qva5qarqubgpvh37s4v6.apps.googleusercontent.com">
   <React.StrictMode>
      <SimpleReactLightbox>
		{/*<Login />*/}
		<App />
      </SimpleReactLightbox>
   </React.StrictMode></GoogleOAuthProvider>,
   document.getElementById("root")
);
reportWebVitals();
